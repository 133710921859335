<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :auto-select="false"
              />
            </th>
            <th role="columnheader">
              <SelectPos
                :label="$t('labels.pos')"
                :placeholder="$t('labels.pos')"
                name="id_pos"
                sort-name="pos_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :auto-select="false"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.ship_tp')"
                :placeholder="$t('labels.ship_tp')"
                name="add_ship_cost"
                sort-name="add_ship_cost"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.delivery_check_goods')"
                :placeholder="$t('labels.delivery_check_goods')"
                name="delivery_check_goods"
                @onFilter="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.delivery_partial')"
                :placeholder="$t('labels.delivery_partial')"
                name="delivery_partial"
                @onFilter="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.delivery_return_required')"
                :placeholder="$t('labels.delivery_return_required')"
                name="delivery_return_required"
                @onFilter="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.source_required')"
                :placeholder="$t('labels.source_required')"
                name="source_required"
                @onFilter="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.note')"
                :placeholder="$t('labels.note')"
                name="note"
                sort-name="note"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="min-width: 95px">
              <v-btn
                small
                color="primary"
                @click="addItem"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, key) in items"
            :key="`dc_${item.id}`"
          >
            <td>
              <span v-if="!item.editing || item.id">{{
                item.warehouse_code
              }}</span>
              <select-warehouse-model
                v-else
                v-model="item.id_warehouse"
                single-line
                :disabled="!!item.id_pos"
                label=""
              ></select-warehouse-model>
            </td>
            <td>
              <span v-if="!item.editing || item.id">{{ item.pos_code }}</span>
              <select-pos-model
                v-else
                v-model="item.id_pos"
                single-line
                :disabled="!!item.id_warehouse"
                label=""
              ></select-pos-model>
            </td>
            <td>
              <span v-if="!item.editing">
                {{ formatNumber(item.add_ship_cost) }}
              </span>
              <input-number
                v-else
                v-model.number="item.add_ship_cost"
                type="number"
                outlined
                dense
                hide-details
                class="c-input-small"
              />
            </td>
            <td>
              <span v-if="!item.editing">{{
                item.delivery_check_goods ? "Yes" : "No"
              }}</span>
              <div v-else class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.delivery_check_goods"
                  class="c-input-small mt-0"
                  dense
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <span v-if="!item.editing">{{
                item.delivery_partial ? "Yes" : "No"
              }}</span>
              <div v-else class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.delivery_partial"
                  class="c-input-small mt-0"
                  dense
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <span v-if="!item.editing">{{
                item.delivery_return_required ? "Yes" : "No"
              }}</span>
              <div v-else class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.delivery_return_required"
                  class="c-input-small mt-0"
                  dense
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <span v-if="!item.editing">
                {{ item.source_required ? "Yes" : "No" }}
              </span>
              <div v-else class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.source_required"
                  class="c-input-small mt-0"
                  dense
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.note }}</span>
              <v-text-field
                v-else
                v-model="item.note"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
                :placeholder="$t('labels.default_note')"
              ></v-text-field>
            </td>
            <td>
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, key, true)"
                  >{{ $t("labels.edit") }}</v-btn
                >
              </template>
              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="updateInfo(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, key, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col cols="3" class="text-right">
        <v-btn
          color="purple"
          class="white--text mt-1"
          @click="showSourceDialog"
          >{{ $t("labels.source") }}</v-btn
        >
      </v-col>
    </v-row>

    <v-dialog v-model="addConfigDialog" persistent max-width="820px">
      <AddConfigDefaultDialog
        v-if="addConfigDialog"
        @cancel="hideAddConfigDialog"
      />
    </v-dialog>

    <v-dialog v-model="sourceDialog" persistent max-width="640px">
      <SourceDialog v-if="sourceDialog" @cancel="hideSourceDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "DefaultConfig",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectPos: () => import("@/components/table/SelectPos"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    AddConfigDefaultDialog: () =>
      import("@/components/account/AddConfigDefaultDialog"),
    SourceDialog: () => import("@/components/account/SourceDialog"),
  },
  data: () => ({
    filters: {},
    isLoading: false,
    page: 1,
    totalPage: 1,
    totalItem: 0,
    items: [],
    addConfigDialog: false,
    sourceDialog: false,
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  watch: {
    page() {
      this.getInfo();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getInfo();
      },
      deep: true,
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showAddConfigDialog() {
      this.addConfigDialog = true;
    },
    hideAddConfigDialog() {
      this.addConfigDialog = false;
    },
    showSourceDialog() {
      this.sourceDialog = true;
    },
    hideSourceDialog() {
      this.sourceDialog = false;
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addItem() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_warehouse: null,
        id_pos: null,
        add_ship_cost: 0,
        delivery_check_goods: 0,
        delivery_partial: 0,
        delivery_return_required: 0,
        source_required: 0,
        note: null,
        editing: true,
      });
    },
    async getInfo() {
      try {
        const { data } = await httpClient.post("/default-config", {
          ...this.filters,
        });
        this.totalPage = data.totalPage;
        this.totalItem = data.total;
        this.items = [...data.rows].map((row) => ({
          ...row,
          editing: false,
        }));
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async updateInfo(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/default-config-update", item);
        this.isLoading = false;
        this.getInfo();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
